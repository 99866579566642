import { css } from '@emotion/react'
import { navigate } from 'gatsby'
import React, { HTMLAttributes } from 'react'
import { Button } from './button'
import { InViewFadeInUp } from './fade-in'

export interface BackBlockProps extends HTMLAttributes<HTMLDivElement> {}

export function BackBlock(props: BackBlockProps) {
  return (
    <InViewFadeInUp
      css={css`
        text-align: center;
      `}
      {...props}
    >
      <Button onClick={() => navigate(-1)}>BACK</Button>
    </InViewFadeInUp>
  )
}
