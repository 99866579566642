import { css, Theme, useTheme } from '@emotion/react'
import React, { HTMLAttributes } from 'react'

// @ts-ignore
import background from '@/images/background-01.svg'
import { Container } from './container'
import { InViewFadeIn } from './fade-in'

export interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  underlineColor?: keyof Omit<Theme['colors'], 'black' | 'white'>
  isDarkColor?: boolean
}

export function PageHeader({
  children,
  underlineColor,
  isDarkColor,
  ...props
}: PageHeaderProps) {
  const theme = useTheme()

  return (
    <InViewFadeIn>
      <div
        css={css`
          padding: 10rem 4rem 11rem;
          font-weight: ${theme.fontWeights.bold};
          background: ${theme.colors.gray.lighter} url(${background}) center /
            192rem;

          @media ${theme.breakpoints.md} {
            padding: 12rem 4rem 14rem;
          }
        `}
        {...props}
      >
        <Container
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          `}
        >
          <h1
            css={css`
              font-size: ${theme.fontSizes.heading2};
            `}
          >
            {children}
          </h1>

          <div
            css={css`
              width: 8.5rem;
              height: 1rem;
              margin: 3rem auto 0;
              background-color: ${underlineColor
                ? isDarkColor
                  ? theme.colors[underlineColor].dark
                  : theme.colors[underlineColor].main
                : theme.colors.black};

              @media ${theme.breakpoints.md} {
                margin-top: 5rem;
                height: 0.7rem;
              }
            `}
            className="underline"
          />
        </Container>
      </div>
    </InViewFadeIn>
  )
}
