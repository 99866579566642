import { BackBlock } from '@/components/back-block'
import { DownloadButton } from '@/components/button'
import { Container } from '@/components/container'
import { InViewFadeInUp } from '@/components/fade-in'
import { PageHeader } from '@/components/page-header'
import {
  StageBlock,
  StageBlockHeader,
  ThreeStagesBlockBody,
  ThreeStagesBlockHeading,
} from '@/components/three-stages-block'
import { css, Theme, useTheme } from '@emotion/react'
import { MDXProvider } from '@mdx-js/react'
import { graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { ReactNode } from 'react'

// @ts-ignore
import decorationBubble2 from '@/images/decoration_bubble-02.svg'
// @ts-ignore
import decorationBubbleSp2 from '@/images/decoration_bubble-02-sp.svg'

const shortcodes = {
  MainSection,
  DownloadButton,
  ScheduleSection,
  ThreeStagesSection,
  ThreeStagesBlockHeading,
  ThreeStagesBlockBody,
  StageBlock,
  StageBlockHeader,
}

export default function IntroductionPage({
  data: { mdx },
}: PageProps<GatsbyTypes.IntroductionPageQuery>) {
  const { body, frontmatter } = mdx ?? {}
  return (
    <section>
      <PageHeader
        underlineColor={
          frontmatter?.themeColor as keyof Omit<
            Theme['colors'],
            'black' | 'white'
          >
        }
      >
        <span
          css={
            frontmatter?.slug?.endsWith('en') &&
            css`
              font-size: 3rem;

              span {
                display: inline-block;
                vertical-align: 0;
              }
            `
          }
          dangerouslySetInnerHTML={{
            __html: frontmatter?.title ?? '',
          }}
        ></span>
      </PageHeader>

      <div
        css={css`
          padding: 12rem 4rem 19rem;
        `}
      >
        <Container>
          <MDXProvider components={shortcodes}>
            <MDXRenderer frontmatter={frontmatter}>{body!}</MDXRenderer>
          </MDXProvider>
        </Container>

        <BackBlock
          css={css`
            margin-top: 16rem;
          `}
        />
      </div>
    </section>
  )
}

function MainSection({ children }: { children?: ReactNode }) {
  const theme = useTheme()

  return (
    <InViewFadeInUp
      css={css`
        padding-bottom: 18rem;
        & p {
          line-height: ${theme.lineHeight.loose};
        }
      `}
    >
      {children}
    </InViewFadeInUp>
  )
}

function ScheduleSection({ children }: { children?: ReactNode }) {
  const theme = useTheme()

  return (
    <section
      id="schedule"
      css={css`
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        background-color: ${theme.colors.gray.lighter};

        h2 {
          position: absolute;
          top: 0;
          left: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateY(-50%);
          width: calc(100% - 8rem);
          height: 10vw;
          max-height: 6.8rem;
          font-weight: ${theme.fontWeights.bold};
          letter-spacing: ${theme.letterSpacings.wide};
          text-align: center;
          background: url(${decorationBubbleSp2}) no-repeat top center / contain;

          @media ${theme.breakpoints.lg} {
            font-size: ${theme.fontSizes.heading3};
            background: url(${decorationBubble2}) no-repeat top center / contain;
            left: 0;
            width: 100%;
          }
        }
      `}
    >
      <InViewFadeInUp>
        <Container
          css={css`
            position: relative;
            padding: 10rem 0 15rem;

            img {
              width: 100%;
            }
          `}
        >
          {children}
        </Container>
      </InViewFadeInUp>
    </section>
  )
}

function ThreeStagesSection({ children }: { children?: ReactNode }) {
  const theme = useTheme()

  return (
    <section
      id="stages"
      css={css`
        position: relative;

        .three-stage-heading {
          margin-top: -3rem;

          @media ${theme.breakpoints.md} {
            margin-top: -4rem;
          }
        }
      `}
    >
      <Container>{children}</Container>
    </section>
  )
}

export const query = graphql`
  query IntroductionPage($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
        themeColor
      }
    }
  }
`
